import React from "react";
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import Layout from '../components/layout4';
import SEO from '../components/seo';
import Sticky from 'react-stickynode';
import { bar, headerleft, headerno, headertitle, headertitle2, headerright, button,csstabgrid, csstabgridinner, csstabgridchild1, csstabgridchild2, csstabgridchild3, csstabgridchild4, csstabgridchild5, dis2, container, hanrei, brackboard, cityset, cesium, syousai, syousaiheader, csssyousaigridinner, csssyousaigridchild000, table, row1, row2, tablediv, taglinks, honbun, tagbutton } from "./blog-post.module.css"
import Hanrei from "../containers/hanrei3"
import CesiumContainer from "../components/cesium/cesiumContainer31"
import Image from '../components/image';

const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;

  const city1 = (typeof window !== 'undefined' && window.localStorage.getItem('cityset1')
    || '');

  const city1nfd = city1.normalize( 'NFD' ) ;
  const kml_filenfd = post.frontmatter.kml_file.normalize( 'NFD' ) ;

  const kml_kmz='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kmz'  
  const kml_kml='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kml'

  const csv='/csv/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-4)




  const { edges } = props.data.allMarkdownRemark;

  var filename="sdg_"+post.frontmatter.sdgs_no+".png";
  
  return (
<Layout>
  <SEO
    title={post.frontmatter.title}
    description={post.frontmatter.description || post.excerpt}
  />
 
 <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
      <div className={bar}>
        <div style={{display:"flex"}}>
          <div className={headerleft}>
            <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}>
              <Image filename={filename}/>
            </a>
          </div>
          <div className={headerno}>
            {post.frontmatter.no}
          </div>
          <div className={headertitle}>
            {post.frontmatter.title}
          </div>
          <div className={headertitle2}>
            {post.frontmatter.title_height_name+"×"+post.frontmatter.title_color_name}
          </div>
          <div className={headerright}>
          <a className={button} href="#">MENU</a>
          </div>
        </div>
      </div>
    </Sticky>





    <div className={csstabgrid}>
      <div className={csstabgridinner}>
        <div className={csstabgridchild1}>
          <Link to={"/"+post.frontmatter.no+"/a/#t"} className={dis2}>
            <h3>自分で地域を選ぶ</h3>
          </Link>
        </div>
        <div className={csstabgridchild2}>
          <Link to={"/"+post.frontmatter.no+"/b/#t"} className={dis2}>
            <h3>地域の昔と比べる</h3>
          </Link>
        </div>
        <div className={csstabgridchild3}>
          <Link to={"/"+post.frontmatter.no+"/c/#t"} className={dis2}>
            <h3>他の地域と比べる</h3>
          </Link>
        </div>
        <div className={csstabgridchild4}>
          <Link to={"/"+post.frontmatter.no+"/d/#t"} className={dis2}>
            <h3>典型例とその解説</h3>
          </Link>
        </div>
        <div className={csstabgridchild5}>
          <Link to={"/"+post.frontmatter.no+"/e/#t"} className={dis2}>
            <h3>国土全体で見る</h3>
          </Link>
        </div>
      </div>
    </div>








    <div className={container}>
	<div className={hanrei}>
      <Hanrei
            frontmatter={post.frontmatter}
            no={post.frontmatter.no}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            sdgs_no={post.frontmatter.sdgs_no}
            sdgs_main={post.frontmatter.sdgs_main}
            sdgs_subcat={post.frontmatter.sdgs_subcat}
            kou_tisou_pt={post.frontmatter.kou_tisou_pt}
            kou_tisou_chp={post.frontmatter.kou_tisou_chp}
            kou_tisou_sec={post.frontmatter.kou_tisou_sec}
            kou_tisou_sbsec={post.frontmatter.kou_tisou_sbsec}
            kou_tisou_title={post.frontmatter.kou_tisou_title}
            kou_titan_pt={post.frontmatter.kou_titan_pt}
            kou_titan_chp={post.frontmatter.kou_titan_chp}
            kou_titan_sec={post.frontmatter.kou_titan_sec}
            kou_titan_sbsec={post.frontmatter.kou_titan_sbsec}
            kou_titan_title={post.frontmatter.kou_titan_title}
            description={post.frontmatter.description}
            height_name={post.frontmatter.height_name}
            height_origin={post.frontmatter.height_origin}
            height_origin_note={post.frontmatter.height_origin_note}
            height_origin_url_name={post.frontmatter.height_origin_url_name}
            height_origin_url_name2={post.frontmatter.height_origin_url_name2}
            height_origin_url={post.frontmatter.height_origin_url}
            height_origin_url2={post.frontmatter.height_origin_url2}
            color_name={post.frontmatter.color_name}
            color_origin_note={post.frontmatter.color_origin_note}
            color_origin={post.frontmatter.color_origin}
            color_origin_url_name={post.frontmatter.color_origin_url_name}
            color_origin_url2_name={post.frontmatter.color_origin_url2_name}
            color_origin_url={post.frontmatter.color_origin_url}
            color_origin_url2={post.frontmatter.color_origin_url2}
            sokuchi={post.frontmatter.sokuchi}
            mesh_size={post.frontmatter.mesh_size}
            year_display={post.frontmatter.year_display}
            honbun={post.frontmatter.honbun}
            chyusyaku={post.frontmatter.chyusyaku}
            hosoku={post.frontmatter.hosoku}
            kml_file={post.frontmatter.kml_file}
            hanrei1_color={post.frontmatter.hanrei1_color}
            hanrei2_color={post.frontmatter.hanrei2_color}
            hanrei3_color={post.frontmatter.hanrei3_color}
            hanrei4_color={post.frontmatter.hanrei4_color}
            hanrei1_name={post.frontmatter.hanrei1_name}
            hanrei2_name={post.frontmatter.hanrei2_name}
            hanrei3_name={post.frontmatter.hanrei3_name}
            hanrei4_name={post.frontmatter.hanrei4_name}
            height_ratio={post.frontmatter.height_ratio}
            kokudo_hanrei1_name={post.frontmatter.kokudo_hanrei1_name}
            kokudo_hanrei2_name={post.frontmatter.kokudo_hanrei2_name}
            kokudo_hanrei3_name={post.frontmatter.kokudo_hanrei3_name}
            kokudo_hanrei4_name={post.frontmatter.kokudo_hanrei4_name}
            kokudo_height_ratio={post.frontmatter.kokudo_height_ratio}      />
    {/* <div className={brackboard} >
      TRY!
      <br/>
      {post.frontmatter.chyusyaku}{post.frontmatter.hosoku}
      <br/>
      <br/>
      <br/>

    </div> */}
  </div>



	<div className={cesium}>
    <CesiumContainer
      year1={post.frontmatter.year1}
      year2={post.frontmatter.year2}
      year3={post.frontmatter.year3}
      year4={post.frontmatter.year4}
      year5={post.frontmatter.year5}
      csv={csv}
      csvmode={post.frontmatter.csvmode}

      kml_kml={kml_kml}
      kml_kmz={kml_kmz}
      // dir="/kml/sample.kml"
      width="70vw"
      height="65vh"
      full_name="全画面表示"
      tofull_url={"/"+post.frontmatter.no+"/fe"}
      full_url={"/"+post.frontmatter.no+"/fe"}
      no={post.frontmatter.no}

      kokudo_min={post.frontmatter.kokudo_min}
      kokudo_ratio={post.frontmatter.kokudo_ratio}
      kokudo_shikii1={post.frontmatter.kokudo_shikii1}
      kokudo_shikii2={post.frontmatter.kokudo_shikii2}
      kokudo_shikii3={post.frontmatter.kokudo_shikii3}
      hanrei1_color={post.frontmatter.hanrei1_color}
      hanrei2_color={post.frontmatter.hanrei2_color}
      hanrei3_color={post.frontmatter.hanrei3_color}
      hanrei4_color={post.frontmatter.hanrei4_color}
      kml1_color={post.frontmatter.kml1_color}
      kml2_color={post.frontmatter.kml2_color}
      kml3_color={post.frontmatter.kml3_color}
      kml4_color={post.frontmatter.kml4_color}
      title={post.frontmatter.title}
      cityname="国土"

    />
  </div>
	{/* <div className={syousai}>

  </div> */}
</div>












{/* 

    <div className={cssgrid}>
      <div className={cssgridchild}>
        <div className={kakomitape4}>
          <span className={titletape4}>
            解説
          </span>
          <p>解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。</p>
        </div>

        <div className={hanrei}>
          <details open>
            <summary className={syousaiheader}>
            <h2>凡例</h2>
            </summary>
            <Hanrei
              frontmatter={post.frontmatter}
            />
          </details>
        </div>
      </div>

      <div className={cssgridchild}>
        <div>
          </div>
            <div className={cesiumcontainer}>
              <CesiumContainer
                kml_kml={kml_kml}
                kml_kmz={kml_kml}
                width="100%"
                height="70vh"
                full_name="Full"
                full_url={"/"+post.frontmatter.no+"/kokudof"}
              />
            </div>
          </div>
        </div> */}



<details>
          <summary className={syousaiheader}>
            データの詳細情報を確認する
          </summary>

<div className={csssyousaigridinner}>
  <div className={csssyousaigridchild000}>
    <div className={table}>
      <div>
        <h2>高さ</h2>
        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={row1}></th>
              <th className={row2}>解説</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>名称</td>
              <td>{post.frontmatter.title_height_name}</td>
              </tr>
              <tr>
              <td>凡例名</td>
              <td>{post.frontmatter.hanrei_height_name}</td>
              </tr>
              <tr>
              <td>年次</td>
              <td>{post.frontmatter.year_display}</td>
              </tr>
              <tr>
              <td>出典</td>
              <td>{post.frontmatter.height_origin}</td>
              </tr>
              <tr>
              <td>定義</td>
              <td>{post.frontmatter.height_origin_note}</td>
              </tr>
              <tr>
              <td>詳細</td>
              <td>
                <a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url3}>{post.frontmatter.height_origin_url3_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url4}>{post.frontmatter.height_origin_url4_name}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <div className={csssyousaigridchild000}>
    <div className={tablediv}>
      <div className={table}>
       <div>
          <h2>色</h2>
          <table>
          <thead>
          <tr>
          <th className={row1}></th>
          <th className={row2}>解説</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>名称</td>
          <td>{post.frontmatter.title_color_name}</td>
          </tr>
          <tr>
          <td>凡例名</td>
          <td>{post.frontmatter.hanrei_color_name}</td>
          </tr>
          <tr>
          <td>年次</td>
          <td>{post.frontmatter.year_display}</td>
          </tr>
          <tr>
          <td>出典</td>
          <td>{post.frontmatter.color_origin}</td>
          </tr>
          <tr>
          <td>定義</td>
          <td>{post.frontmatter.color_origin_note}</td>
          </tr>
          <tr>
          <td>詳細</td>
          <td>
            <a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url3}>{post.frontmatter.color_origin_url3_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url4}>{post.frontmatter.color_origin_url4_name}</a>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </div>

</details>


<details>
  <summary className={syousaiheader}>
      関連するデータを調べる
  </summary>

  {/* <div className={csssyousaigridinner}>
    <div className={csssyousaigridchild000}>
      <div> */}
        {post.frontmatter.tags == null ? null : (
        <div className={taglinks}>
          {post.frontmatter.tags.map((tag: string, index: number) => (
          <div className={tagbutton}>
            <Link
            key={index}
            to={`/tags/${_.kebabCase(tag)}/`}
            >
              {`${tag}`}
            </Link>
          </div>
          ))}
        </div>
        )}
      {/* </div>
    </div> */}
{/* 
    <div className={csssyousaigridchild000}>
      <div>
        <h2>
          関連ページ
        </h2>
      </div>
      <div style={{display:"flex"}}>
        <div style={{margin:"0% 5% 0% 5%"}}>
            {edges.length !== 0 && (
          <div>
            {edges.map(({ node }: any) => (
            <div key={node.fields.slug}>
              <PostCard
                title={node.frontmatter.title || node.fields.slug}
                url={node.fields.slug}
                tags={node.frontmatter.tags}
                sdgs_no={node.frontmatter.sdgs_no}
                height_name={node.frontmatter.height_name}
                color_name={node.frontmatter.color_name}
              />
            </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  */}
  {/* </div> */}

</details>



</Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySluge($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
title_height_name
hanrei_height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
title_color_name
hanrei_color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_color
        hanrei2_color
        hanrei3_color
        hanrei4_color
        hanrei1_name
        hanrei2_name
        hanrei3_name
        hanrei4_name
        height_ratio
        year1
        year2
        year3
        year4
        year5
        tenkei
        try
        min
        ratio
        shikii1
        shikii2
        shikii3
        csvmode
        kml1_color
        kml2_color
        kml3_color
        kml4_color

        kokudo_min
        kokudo_ratio
        kokudo_shikii1
        kokudo_shikii2
        kokudo_shikii3
        kokudo_hanrei1_name
        kokudo_hanrei2_name
        kokudo_hanrei3_name
        kokudo_hanrei4_name
        kokudo_height_ratio


      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
title_height_name
hanrei_height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
title_color_name
hanrei_color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
    
            year1
            year2
            year3
            year4
            year5
            tenkei
            try
            min
            ratio
            shikii1
            shikii2
            shikii3
            csvmode
            kml1_color
            kml2_color
            kml3_color
            kml4_color
    
            kokudo_min
            kokudo_ratio
            kokudo_shikii1
            kokudo_shikii2
            kokudo_shikii3
            kokudo_hanrei1_name
            kokudo_hanrei2_name
            kokudo_hanrei3_name
            kokudo_hanrei4_name
            kokudo_height_ratio
    
          }
        }
      }
    }
  }
`;

